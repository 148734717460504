.InputContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    font-size: 16px;
    color: #3F3F3F;
    width: 100%;
    row-gap: 5px;
}

.MuiInputBase-root.MuiOutlinedInput-root{
    background-color: #fff;
    padding-top: 2px;
    padding-bottom: 2px;
    padding-left: 8px;
    padding-right: 8px;
    font-size: 14px;
    width: 100%;
    
}

.MuiTextField-root {
    max-width: 700px;
}

.MuiInputBase-input {
    padding-top: 6px !important;
    padding-bottom: 6px !important;    
    height: 20px !important;
}

.BooleanPage{
    padding-top: 2vh;
    padding-bottom: 4vh;
}

.ChatContainer {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    flex: 1 1 auto;
    background: #F8F8FB;
    color: #3F3F3F;
    font-size: 16px;
    justify-content: space-between;
    max-width: 98%;
    width: 700px;
    padding: 10px 20px;
    overflow: auto;
    border-radius: 4px
}

.ChatContainer2 {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    color: #3F3F3F;
    font-size: 16px;
    justify-content: space-between;
    max-width: 98%;
    width: 700px;
    padding: 10px 20px;
    overflow: auto;
    margin-bottom: 20px;
}



.TryNowButton.BooleanSearchButton {
    height: 32px;
    font-size: 14px;
    border-radius: 8px;
}

.TryNowButton.BooleanSearchButton.Mui-disabled {
    background: rgba(255,232,110, 0.5);
    color: rgba(13,26,56, 0.5);
    border-color:  rgba(13,26,56, 0.5);
}

.Message {
    display: flex;
    flex-direction: column;
    row-gap: 5px;
}

.Message .Name {
    font-weight: 500;
    /* font-size: 18px; */
    color: #17204E;
}

.Message .MessageContent {
    padding: 4px 8px;
    border: 1px solid #CECECE;
    border-radius: 8px;
}

.YouMessage {
    align-items: flex-end;
}

.YouMessage .MessageContent {
    margin-left: 10px;
    background-color: #FAF7ED;
}

.TaylrMessage {
    align-items: flex-start;
}

.TaylrMessage .MessageContent {
    margin-right: 10px;
    background-color: #ECF4F9;
}


.MuiTextField-root{
    /* height: 100%;
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start; */
    width: 100%;
}

.BooleanSearchExamples {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    column-gap: 10px;
}

.BooleanSearchExample {
    font-size: 16px;
    background-color: #fff;
    /* background-color: rgba(0,0,0, 0.1); */
    color: #3F3F3F;
    padding: 4px 8px;
    border-radius: 8px;
    text-align: center;
    border: 1px solid #CECECE;
    min-height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.BooleanSearchExample:hover {
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
}

.BooleanSearchRefineExamples {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    column-gap: 10px;
}

.BooleanSearchRefineExample {
    font-size: 14px;
    background-color: #fff;
    /* background-color: rgba(0,0,0, 0.1); */
    color: #3F3F3F;
    padding: 4px 8px;
    border-radius: 8px;
    text-align: center;
    border: 1px solid #CECECE;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.TaylrMessageButtons {
    display: flex;
    flex-direction: row;
    column-gap: 5px;
    min-height: 34px;
}

.TaylrMessageButtons button {
    display: none;
}

.TaylrMessage:hover button {
    display: flex;
}

.TaylrMessage.TaylrMessageActive button {
    display: flex;
}

.TaylrMessage .LinkedInSearchButton {
    background-color: #113263;
    color: #F0F0F0;
    font-size: 13px;
    text-transform: capitalize;
    height: 32px;
    padding: 4px 16px;
    border-radius: 8px;
}

.TaylrMessage .LinkedInSearchButton:hover {
    background-color: #113263;
}

.TaylrMessage .CopyButton {
    background-color: #113263;
    color: #F0F0F0;
    font-size: 13px;
    text-transform: capitalize;
    height: 32px;
    padding: 4px  8px;
    border-radius: 8px;
}

.TaylrMessage .CopyButton.Mui-disabled {
    background-color: #6D83A5;
    color: #F0F0F0;
}

.TaylrMessage .LinkedInSearchButton.Mui-disabled {
    background-color: #6D83A5;
    color: #F0F0F0;
}

.TaylrMessage .CopyButton:hover {
    background-color: #113263;
}

.TaylrTooltip .MuiTooltip-tooltip {
    background-color: #fff;
    border-radius: 4px;
    color: #323232;
    font-family: 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    padding: 6px 12px;
    font-size: 14px;
    max-width: 400px;
    margin: 2px;
    /* word-wrap: break-word; */
    font-weight: 400;
    border: 1px solid #CECECE;
    box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.25);
  }

  .TaylrTooltip .MuiTooltip-arrow{
    color: #CECECE;
  }

  .BooleanSearchQuery {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    color: #3F3F3F;
    font-size: 16px;
    justify-content: space-between;
    max-width: 98%;
    width: 700px;
    padding: 10px 20px;
    overflow: auto;
    margin-bottom: 20px;
    row-gap: 10px;
}

.BooleanSearchQueryOutputTitle{
    text-align: center;
    margin-bottom: 5px;
    color: #3f3f3f;
    font-size: 20px;
    font-weight: 500;
}

.BooleanSearchQueryOutputContainer{
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 10px;
    border-radius: 8px;
    background-color: #fff;
    /* border: 1px solid #CECECE; */
    /* row-gap: 10px; */
    filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.15));
}
.BooleanSearchSections{
    display: flex;
    flex-direction: column;
    align-items: stretch;
    row-gap: 5px;
    margin-top: 40px;
}

.BooleanSearchSectionMainTitle{
    text-align: center;
    font-weight: 500;
}

.BooleanSearchSection{
    padding: 10px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    row-gap: 8px;
}

.BooleanSearchSectionGrid{
    display: grid;
    grid-template-columns: 200px 3fr;
    column-gap: 0px;
    row-gap: 4px;
}

.BooleanSearchSectionTitle{
    font-weight: 600;
    font-size: 17px;
    padding-bottom: 4px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.BooleanSearchSectionAlternativeTitle{
    font-weight: 500;
    font-size: 16px;
    display: flex;
    align-items: baseline;
    margin-top: 2px;
    padding-bottom: 4px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    justify-content: space-between;
}

.BooleanSearchSectionAlternatives{
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 4px;
    flex-wrap: wrap;
    padding-bottom: 4px;
    row-gap: 4px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.BooleanAddtoSection{
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 4px;
    flex-wrap: wrap;
    font-size: 12px;
}

.BooleanAddButton{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    font-size: 14px;
    font-weight: 500;
    height: 22px;
    width: 26px;
    color: rgba(0, 0, 0, 0.6);
    /* height: 20px; */
    cursor: pointer;
    justify-content: center;
    /* padding-bottom: 4px; */
}

.inputButtonContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
}


.ModalContainer {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Modal{
    width: calc(90% - 40px);
    max-width: 600px;
    padding: 20px;
    border-radius: 8px;
    color: #3F3F3F;

}

.ModalTitle{
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 10px;
    text-align: center;
}

.GeneratingIcon{
    width: 20px;
    margin-right: 5px;
    margin-left: -20px;
    margin-top: 10px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

}

.ModalContent{
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    align-items: stretch;
    row-gap: 10px;
}

.ModalSectionValues {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 4px;
    flex-wrap: wrap;
    padding-top: 4px;
    padding-bottom: 4px;
    row-gap: 4px;
}

.ModalInputs{
    display: flex;
    flex-direction: column;
    align-items: center;
    column-gap: 4px;
    flex-wrap: wrap;
    padding-bottom: 4px;
    row-gap: 4px;
}

.GeneratingIcon .MuiCircularProgress-root{
    width: 20px !important;
    height: 20px !important;
}

.Pointer {
    cursor: pointer !important;
}

.BooleanSearchSectionAlternative.Included{
    background-color: rgba(0, 0, 0, 0.35);
}

.ModalSectionInstructions{
    font-size: 12px;
}
/* .LightningIcon{
    color: rgba(0, 0, 0, 0.6);
} */

.BooleanSearchSectionAlternative{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    font-size: 14px;
    padding: 2px 8px;
    /* height: 20px; */
    justify-content: center;
    padding-bottom: 4px;
    position: relative;
    cursor: default;
    user-select: none;
}

.RemoveValue{
    display: none;
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 20px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-color: rgba(255, 164, 164, 0.8);
    border-radius: 0 8px 8px 0;
}

.BooleanSearchSectionAlternative:hover .RemoveValue{
    display: flex;
}

.BooleanSearchButtons {
    display: flex;
    flex-direction: row;
    column-gap: 5px;
    justify-content: right;
}

.BooleanSearchSectionValue{
    padding-bottom: 4px;
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    align-items: flex-start;
    margin-right: 2px;
}

.BooleanSearchCategory{
    border-radius: 4px;
    margin: 2px;
}

.BooleanSearchQueryDisplay{
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    padding: 8px 12px;
    font-size: 16px;
    background-color: #fff;
}

.BooleanAdditionalSections{
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 10px;
    flex-wrap: wrap;
    justify-content: center;
}

.BooleanSearchAddSection{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px;
    border-radius: 8px;
    color:rgba(0, 0, 0, 0.6);   
    font-weight: 500;
    cursor: pointer;
}

.GeneratingIconMenu {
    height: 20px !important;
    width: 20px !important;
    color:rgba(0, 0, 0, 0.5) !important;
}

.GeneratingIconButton {
    height: 12px !important;
    width: 12px !important;
    color:rgba(0, 0, 0, 0.5) !important;
}

.AddValueTextField {
    max-width: 150px;
}

.AddValueTextField .MuiInputBase-input {
    padding: 2px 4px;
    font-size: 12px;
}

.BooleanTickButton {
    background-color: rgba(110, 221, 76, 0.3);
    border-radius: 4px;
    padding: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 14px;
}

.BooleanCrossButton {
    background-color: rgba(221, 76, 76, 0.3);
    border-radius: 4px;
    padding: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 14px;
}

.ModalAddSection{
    display: flex;
    flex-direction: row;
    column-gap: 4px;
    align-items: center;
}

.BooleanOperatorDropdownContainer{
    font-size: 12px;
}

.BooleanOperatorDropdownContainer .BooleanOperatorDropdown{
    font-size: 13px;
    height: 30px;
    padding: 0px;
    width: 60px;
}

.BooleanOperatorDropdown .MuiSelect-select{
    padding-right: 24px !important;
    padding-left: 8px !important;
}

.BooleanOperatorDropdown .MuiSelect-icon{
    right: 2px !important;
}

@media (max-width: 400px) {
    .BooleanSearchSectionGrid{
        grid-template-columns: 150px 3fr;
    }

    .BooleanSearchButtons {
        flex-wrap: wrap;
        row-gap: 5px;
    }

    .TaylrHomePageSecondaryTitle{
        font-size: 30px;
    }

    .TaylrHomePageSecondaryText{
        font-size: 16px;
    }

    .BooleanSearchQueryOutputTitle{
        font-size: 16px;
    }

    .BooleanSearchSectionTitle{
        font-size: 14px;
        margin-top: 2px;
    }
    .BooleanSearchSectionAlternativeTitle{
        font-size: 14px;
    }
    .BooleanOperatorDropdownContainer .BooleanOperatorDropdown{
        height: 26px;
    }
}