


.HighlightedText {
  color: #FFE86E;
  font-weight: 700;
  display: inline-flex !important;
  max-height: 40px;
}

.HomePageTop{
  color: #F9FAFC;

}

.TaylrHeader {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 62px;
  line-height: 73px;

  color: #F9FAFC;
}

.TaylrHeaderSecondary {
  display: flex;
  flex-direction: column;
  font-weight: 600;
  font-size: 34px;
  line-height: 40px;

  color: #F9FAFC;
}

.TaylrHeaderSecondary #text{
  display: inline-flex;
}

.TaylrHeaderSecondaryText {
  white-space: nowrap
}


.navigation {
  /* height: 60px; */
  width: calc(100% - 40px);
  display: flex;
  align-items: center;
  position: relative;
  padding: 10px 20px;
  background-color: #0D1A38;
   /* #050D1F; */
  color: #cfcfcf;
  /* box-shadow: 0 2px 2px 2px rgba(9, 9, 9, 0.23); */
}


.TaylrHomePageTop{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 5vh;
  padding-bottom: 10vh;
  background-color: #0D1A38;
  color: #cfcfcf;
}

.TaylrHomePage{
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
}

.FullHeight{
  display: flex;
  flex: 1 1 auto;
  height: auto;
}

.TaylrHomePageTopSection{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 1200px;
  padding: 10px 20px;
  align-items: center;
  width: calc(100% - 20px);
}

.TaylrHomeImgContainer{
  width: 40%;
  margin-top: 40px;
  margin-bottom: 40px;
}

.TaylrHomePageImg{
  width: 100%;
  height: auto;

  border-radius: 12px;
}

.NavLinkButtonContainer {
  margin-left: 20px;
}


.MuiButton-root.NavLinkButton{
  background-color: #0D1A38;

  border: 1px solid #FFFFFF;
}

.FooterContent {
  background-color: #0D1A38;
  color: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
  padding-bottom: 20px;
}

.FooterSearches {
  align-items: stretch;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 100%;
  padding: 10px 20px;
  width: 800px;
}

/* .FooterTitle {
  padding-right: 20px;
}

.FooterEmail {
  padding-right: 20px;
  padding-left: 20px;
}

.FooterLogoContainer {
  padding-right: 20px;
  padding-left: 20px;
} */

.MuiButton-root.TaylrButton{
  text-transform: none;
  text-decoration: none;
  padding-left: 24px;
  padding-right: 24px;
  font-size: 16px;
  border-radius: 24px;
}

.TaylrHomeButtonContainer{
  display: flex;
  padding-top: 20px;
  text-decoration: none;
  padding-bottom: 20px;
  max-width: 162px;
}

.TaylrHomePageSecond{
  padding-top: 10vh;
  padding-bottom: 10vh;
  display: flex;
  font-weight: 400;
  font-size: 24px;
  background: #F3F5F9;
  color: #17204E;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.TaylrHomePageSection{
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
}

.TaylrHomePageSecondaryTitle{
  font-weight: 700;
  font-size: 35px;
  line-height: 40px;
  text-align: center;
  color: #6C9699;
  margin-block-start: 0px;
  margin-block-end: 0px;
  margin-bottom: 10px;
  max-width: 700px;
}

.TaylrHomePageSecondaryText{
  max-width: 700px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #17204e;
  font-weight: 400;
  font-size: 20px;
  text-align: center;
  margin-block-start: 0px;
  margin-block-end: 0px;
}

.TaylrHomePageSecondaryText ul {
  text-align: left;
}

.TaylrBoldHeader{
  font-size: 24px;
  font-weight: 600;
}

.TaylrHomePageSecondaryText ul {
  margin-top: 5px;
  margin-bottom: 5px;
}

.TaylrPricingTitle{
  color: #FFE86E;
  font-weight: 700;
  font-size: 40px;
  line-height: 50px;
  text-align: center;
  margin-bottom: 10px;
}

.TaylrPricingSubtitle{
  color: #F3F3F3;
  font-weight: 400;
  font-size: 28px;
  margin-bottom: 40px;
  text-align: center;
}

.PricingContactUs{

  margin-top: 40px;
  margin-bottom: 40px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.PricingContactUsTitle{
  color: #F3F3F3;
  font-weight: 400;
  font-size: 22px;
  margin-bottom: 10px;
}

.PlansContainer{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  column-gap: 30px;
  row-gap: 20px;
}

.Plan{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  row-gap: 10px;
  padding: 30px 20px;
  border-radius: 10px;
  background-color: #F8F8FB;
  /* box-shadow: 0px 3px 3px rgb(0 0 0 / 25%); */

}



.PlanTitle {
  font-weight: 500;
  font-size: 34px;
  text-align: center;
  color: #182037;
}

.PlanDescription {
  font-weight: 400;
  font-size: 20px;
  max-width: 300px;
  text-align: center;
  color: #0D1A38;
}

.PlanBullets{
  font-weight: 400;
  font-size: 16px;
  max-width: 250px;
  text-align: left;
  color: #4B5763;
  width: 100%;
}

.PlanBullets ul{
  margin-block-start: 5px;
  margin-block-end: 5px;
  padding-inline-start: 25px;
}

.PlanTimeSaving{
  margin-top: 10px;;
  color: #0D1A38;
  font-weight: 600;
  font-size: 16px;
  text-align: center;
}

.PlanTimeSavingText{
  color: #4B5763;
  font-weight: 400;
  font-size: 16px;
}

.PlanPrice {
  color: #182037;
  font-weight: 500;
  font-size: 57px;
  display: flex;
  flex-direction: row;
  align-items: stretch;
}

.PoundIcon{
  font-size: 24px;
  margin-top: 20px;
  margin-right: 3px;
}

.PlanPriceInterval{
  vertical-align: baseline;
  font-size: 24px;
  margin-right: 3px;
  align-self: flex-end;
  margin-bottom: 10px;
}

.PlanCredits{
  margin-top: -10px;
  font-weight: 400;
  font-size: 17px;
  line-height: 20px;
  /* identical to box height */

  text-align: center;

  color: #4B5763;
}

.PlanNumbeUsers{
  font-weight: 400;
  font-size: 18px;
  max-width: 300px;
  text-align: center;
  color: #0D1A38;
  margin-top: 30px;
}

/* .PlanButton{
  margin-bottom: 10px;
} */

.PlanBilling {
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  /* identical to box height */

  text-align: center;

  /* Dark Grey */

  color: #3F3F3F;
  margin-bottom: 20px;
}

.PlanButton {
  text-transform: none !important;
  text-decoration: none !important;
  padding-left: 24px !important;
  padding-right: 24px !important;
  font-size: 16px !important;
  border-radius: 24px !important;
}

.CheckoutButton {
  background: #17204E !important;
  color: #EEF0F8 !important;
  border: 1px solid #15395D !important;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  border-radius: 27px;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}


/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
  background-color: rgba(0, 0, 0, 0.02);
  border-radius: 4px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}

.MuiButton-root.TryNowButton{
  height: 52px;
  font-size: 20px;
  text-decoration: none;
  background: #FFE86E;
  color: #0D1A38;
  border: 1px solid #0D1A38;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-weight: 700;
}

.MuiButton-root.TryNowButton:hover {
  background: #f9e68b;
}


.TryNowButton #text{
  font-weight: 700;
  text-decoration: none;
}

.MuiButton-root.NavLinkButton:hover{
  background-color: #112249;
}

.dashboard-logo{
  /* padding: 10px; */
  padding-left: 0px;
  color: #3F3F3F;
  font-weight: 700;
  font-size: 28px;
  line-height: 33px;
  color: #FFFFFF;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
}

.TaylrLogo{
  height: 22px;
  margin-right: 4px;
  margin-top: 1px;
  margin-bottom: -1px;
}

.dashboard-logo-ai {
  color: #FFE86E;
}


.MuiButton-root.LoginButton{
  text-decoration: none;
  background: #FFE86E;
  color: #0D1A38;
  border: 1px solid #0D1A38;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.TaylrHomePageBlue {
  background-color: #0D1A38;
  color: #DFDFDF;
  padding: 10%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.TaylrHomePageBenefitsSection{
  max-width: 900px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.TaylrHomePageYellowTitle {
  font-weight: 700;
  font-size: 38px;
  line-height: 45px;
  text-align: center;
  color: #FFE86E;
  margin-bottom: 20px;
}

.TaylrHomePageSectionSubtitle {
  font-weight: 400;
  font-size: 24px;
  line-height: 128.69%;
  /* or 31px */

  text-align: center;

  color: #DFDFDF;
}

.TaylrHomePageBenefits {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  width: 100%;
  margin-top: 50px;
  flex-wrap: wrap;
  column-gap: 10px;
}

.TaylrHomePageBenefit{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: calc(30% - 40px);
  /* min-width: 200px; */
  background: #F6F9FF;
  padding: 25px;
  border-radius: 10px;
}

.TaylrHomePageBenefitTitle{
  font-weight: 500;
  font-size: 24px;
  line-height: 27px;
  text-align: center;
  min-height: 54px;
  color: #0F1D3C;
  margin-bottom: 10px;
  max-width: 200px;
}

.TaylrHomePageBenefitText {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 120.69%;
    /* or 21px */
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: center;
    
    color: #0B152C;
}

.TaylrHomePageTestimonials{
  padding-top: 20px;
  padding-bottom: 40px;
  display: grid;
  /* flex-direction: row; */
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  align-items: stretch;
  max-width: 1100px;
  column-gap: 20px;
}

.TaylrHomePageTestimonial {
  padding: 20px;
  background: #FFFFFF;
  border: 1px solid #D8DEEB;
  box-shadow: 0px 2px 4px rgba(246, 249, 255, 0.25);
  border-radius: 8px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}

.TaylrHomePageTestimonialText {
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 120.69%;
  color: #293247;
}

.TaylrTestimonialProfile{
  display: flex;
  flex-direction: row;
  align-items: center;
  
}

.TaylrTestimonialProfileText{
  display: flex;
  flex-direction: column;
  font-size: 15px;
  color: #3F3F3F;
}

.TestimonialName{
  font-size: 17px;
  font-weight: 600;
}

.TestimonialCompany{
  font-weight: 500;
  text-decoration: none;
  color: #3F3F3F;
}

.TaylrHomePageTestimonialQuote{
  color: #293247;
  font-size: 30px;
}

.TaylrHomePageFeatures {
  padding-top: 20px;
  padding-bottom: 40px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 20px;
  row-gap: 20px;
}

.TaylrHomePageFeature{
  display: flex;
  flex-direction: row;

}

.TaylrHomePageFeatureTitle {
  font-weight: 500;
  font-size: 23px;
  color: #DFDFDF;
  margin-bottom: 5px;
}

/* .TaylrHomePageFeatureDescription{

} */

.TaylrHomePageFeatureIcon{
  font-size: 24px;
  margin-right: 4px;
  position: relative;
  min-width: 34px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.LinkedInLogo{
  margin-top: 4px;
  position: relative;
  color: #0A66C2;
  z-index: 2;
}

.LinkedInLogoBackground {
  position: absolute;

  top: 6px;
  width: 24px;
  height: 22px;
  background-color: #fff;
  border-radius: 4px;
  z-index: 0;
}

.TestimonialImage{
  height: 60px;
  width: 60px;
  border-radius: 50%;
  margin-right: 10px;
}

.MuiButton-root.LoginButton:hover {
  background: #f9e68b;
}

.NavLinkButtonContainer {
  text-decoration: none;
}

.brand-name {
  text-decoration: none;
  color: black;
  font-size: 1.3rem;
  margin-left: 1rem;
}

.navigation-menu {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 10px;
  margin-left: auto;
}

.NavLink {
  font-size: 18px;
  color: #fff;
  text-decoration: none;
  padding: 10px 20px;
}


.hamburger {

  border: 0;
  height: 40px;
  width: 40px;
  padding: 0.5rem;
  border-radius: 50%;
  background-color: #0D1A38;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  position: absolute;
  top: 50%;
  right: 25px;
  transform: translateY(-50%);
  display: none;
}

.hamburger:hover {
  background-color: #FFE86E;
  color: #3f3f3f;
}

@media (max-width: 768px) {
  .TaylrHomePageTopSection {
    flex-direction: column;
  }

  .TaylrHomeImgContainer{
    width: calc(100% - 40px);
  }
  
  .TaylrHomePageBenefit{
    padding: 10px;
    width: calc(30% - 20px);
  }

  .hamburger {
    display: block;
  }

  .navigation-menu {
    position: absolute;
    top: 52px;
    right: 0;
    flex-direction: column;
    width: 200px;
    max-width: 100%;
    height: auto;
    background-color: #0c1d43;
    /* border-top: 1px solid #FFE86E; */
    display: none;
  }
  .navigation-menu li {
    text-align: center;
    margin: 0;
  }
  .navigation-menu li a {
    color: black;
    width: 100%;
    padding: 1.5rem 0;
  }
  .navigation-menu .NavLink:hover {
    color: #FFE86E
  }

  .navigation-menu.expanded {
    display: flex;
  }

  .navigation-menu.expanded .NavLink {
    display: flex;
    border-bottom:  1px solid #0D1A38; 
  }

  .navigation-menu .NavLink {
    /* previous styles */
    display: none;
  }

  .navigation-menu.expanded .NavLinkButtonContainer {
    display: block;
    
  }

  .navigation-menu .NavLinkButtonContainer {
    /* previous styles */
    display: none;
    /* margin-left: 0px; */
    margin: 10px;
  }
}

@media (max-width: 600px) {

  .TaylrHeaderSecondary #text{
    display: block;
  }

  .HighlightedText {
    display: block !important;
  }

  .TaylrHeader {
    font-size: 42px;
    line-height: 53px;
  }

  .TaylrHeaderSecondary{
    font-size: 24px;
    line-height: 29px;
  }

  .TaylrHomePageFeatures {
    grid-template-columns: 1fr;
  }

  .TaylrHomePageBenefits{
    flex-direction: column;
    align-items: center;
    row-gap: 20px;
  }

  .TaylrHomePageBenefit{
    width: 90%;
    max-width: 200px;
  }

  .TaylrHomePageTestimonials{
    /* flex-direction: column; */
    grid-template-columns: 1fr;
    align-items: center;
    row-gap: 20px;
    padding: 20px;
  }
}

@media (max-width: 500px) {
  .TaylrHeader {
    font-size: 42px;
    line-height: 53px;
  }

  .TaylrHeaderSecondary{
    font-size: 24px;
    line-height: 29px;
  }

  .TaylrHomePageFeatures {
    grid-template-columns: 1fr;

  }

  /* .TaylrHomePageBenefits{

  } */
}